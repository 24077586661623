@import 'variables';

.ant-form-item-control {
  .ant-form-item-control-input {
    min-height: $form-item-height;

    .ant-select {
      & > .ant-select:not(.ant-select-customize-input),
      & > .ant-select-selector {
        @include form-element;
        align-items: center;

        input {
          height: $form-item-height;
        }

        & > .ant-select-selection-overflow {
          & > .ant-select-selection-overflow-item {
            margin-top: -6px;

            .ant-tag {
              @include tag-container;
            }
          }
        }
      }

      & > .ant-select-clear,
      & > .ant-select-arrow {
        right: 7px;
      }

      &.ant-select-disabled {
        & > .ant-select-selector {
          color: $form-text-disabled;
        }
      }
    }

    input,
    .ant-picker,
    .ant-input-affix-wrapper {
      @include form-element;

      .ant-picker-suffix {
        color: $blue-01;
      }
    }

    .ant-input-affix-wrapper {
      padding: 0 11px;

      input {
        height: 22px;
      }
    }

    .ant-picker {
      width: 100%;

      .ant-picker-input {
        input {
          padding-left: 0;
        }
      }
    }

    .ant-input[disabled] {
      color: $form-text-disabled;
      cursor: text;
    }
  }

  .ant-form-item-explain {
    font-size: 10px;
    color: $red;
    min-height: auto;
    height: 10px;
  }
}

.ant-select-dropdown {
  .ant-select-item-option {
    font-size: 12px;
    min-height: 20px;
    line-height: 15px;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $highlight-blue-at-30;
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    .ant-picker-panel {
      font-size: 12px;
    }
  }
}

.ant-divider {
  &.ant-divider-horizontal {
    margin: 14px 0;
  }
}

.label-text {
  @include label-text;
}

.ant-btn-link {
  &.staff-link-text {
    color: black;
    font-size: 14px;
    font-weight: 700;
    padding-top: 0;
    height: $form-item-height;

    &.selected {
      color: $highlight-blue;
    }

    span {
      text-decoration: underline;
    }
  }
}

.location-details-form,
.manager-form,
.manager-details-form,
.profile-details-form,
.mvc-details-form,
.region-district-details-form,
.region-district-bulk-update-form,
.marketing-details-form,
.schedules-details-form,
.it-details-form,
.telecom-details-form,
.specialty-details-form,
.pro-service-details-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0;

  > div {
    width: 100%;
  }

  &-zip,
  &-city,
  &-state {
    width: 30% !important;
    margin-right: 8px !important;
  }

  .ant-form-item {
    margin-bottom: 0;

    &.checkbox-item {
      width: 85px;
      display: inline-block;
    }

    .ant-form-item {
      &-label {
        padding-bottom: 0;

        > label {
          @include label-text;
          display: flex;
          align-items: flex-end;
          padding-top: 6px;
        }
      }

      &-control {
        // height: 24px;
      }
    }
  }

  .assigned-text {
    color: $blue-01;
    font-weight: 700;
    font-size: 14px;
    margin-top: 5px;
  }
}

.schedules-details-form {
  .ant-form-item {
    .ant-form-item-control {
      .ant-form-item-control-input-content {
        font-size: 10px;

        .ant-checkbox-wrapper {
          font-size: 10px;
          font-weight: 800;
          align-items: center;
          margin-left: 15px;

          .ant-checkbox + span {
            padding-top: 6px;
            padding-left: 6px;
            line-height: 1;
          }
        }

        .ant-input.time-input {
          width: 45px;
          height: 20px;
          border-radius: 10px;
          font-size: 10px;
          padding: 0 7px;
          margin-right: 5px;
        }
      }

      .ant-form-item-explain {
        position: absolute;
        top: 22px;
        min-width: 110px;
        left: 0;
      }
    }
  }

  button.ant-switch {
    background-color: #fff;
    border: 1px solid $blue-01-at-30;
    height: 20px;
    min-width: 35px;
    background-image: none;

    &.ant-switch-checked {
      background-color: $blue-01-at-30;
      color: $blue-01;

      .ant-switch-handle {
        left: calc(100% - 14px - 2px);
      }

      .ant-switch-inner {
        margin: 0 18px 0 4px;
      }
    }

    > .ant-switch-handle {
      width: 14px;
      height: 14px;

      &::before {
        background-color: $blue-02;
      }
    }

    > .ant-switch-inner {
      color: $blue-01;
      font-size: 8px;
      font-weight: 800;
      line-height: 18px;
      margin: 0 4px 0 18px;
    }
  }

  .schedule-label {
    @include label-text;
    margin-top: 10px;
  }

  .schedule-time-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;

    .time-divider {
      margin: 0 8px;
    }
  }
}

.manager-details-form-header {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  @include header;

  button.ant-btn {
    border: none;
    padding: 0;
    background: none;
    font-weight: 800;

    :focus,
    :hover {
      background: none;
    }

    span {
      font-size: 11px;
    }
  }
}

.profile-menu {
  font-size: 12px;

  .username {
    padding: 10px 15px 0;
  }

  .ant-dropdown-menu-item {
    padding: 0;

    .ant-btn {
      font-size: 12px;
      font-weight: 700;

      span {
        text-decoration: underline;
      }
    }
  }
}

.search-container.ant-form {
  display: flex;
  align-items: flex-end;
  min-height: 60px;
  padding: 10px 10px 0 10px;
  margin: 0 10px 20px 0;
  background: linear-gradient(92deg, rgba(177, 138, 100, 62%), #84c3e6 100%);
  border-radius: $default-border-radius;

  .ant-form-item {
    margin-right: 15px;
    margin-bottom: 13px;

    &.search-action {
      min-width: 75px;
      width: 120px;
    }

    &.input-field {
      min-width: 145px;
      width: 170px;
      @include input-clear-suffix;
    }

    &.search-btn {
      margin-bottom: 10px;
    }

    &.active-only {
      margin-right: 35px;
      margin-left: auto;
    }

    &.status-type {
      min-width: 225px;
    }
     &.pos-field {
      min-width: 120px;
     }

    &.district-field {
      width: 150px;
    }

    &.region-field {
      width: 150px;
    }

    &.specialty-field {
      min-width: 170px;
    }
    &.proServices-field {
      min-width: 170px;
    }

    &.specialty-part-field {
      min-width: 140px;
    }

    &.doctor-search-field {
      min-width: 230px;
    }

    &.area-field {
      min-width: 100px;
    }

    &.equipment-field {
      min-width: 150px;
    }

    &.teleoptometry-field {
      min-width: 110px;
    }

    &.insurance-select {
      width: 180px;
    }

    &.effective-date-fld {
      width: 120px;
    }

    &.text-search {
      width: 150px;
      @include input-clear-suffix;
    }

    &.lan-subnet-search {
      width: 150px;

      @include input-clear-suffix;
    }

    &.address-srch {
      width: 200px;
      @include input-clear-suffix;
    }

    &.effective-date-fld {
      width: 150px;
    }

    > .ant-form-item-row .ant-form-item {
      &-label {
        padding-bottom: 0;

        > label {
          @include label-text;
          color: #fff;
          padding-bottom: 2px;
          display: flex;
        }
      }
    }

    .ant-btn {
      background-color: $blue-01;
      border-color: $blue-01;
      &.btn-advanced-search {
        border-left: 1px solid #FFF;
      }
    }

    .ant-form-item-control {
      .ant-form-item-explain {
        display: none;
      }
    }
  }

  & .location-search {
    flex-direction: column;
    align-items: center;
    padding: 10px 10px;
    margin: 10px 10px 20px 0;


    .ant-form-item {

      &.input-field {
        min-width: 145px;
        width: 210px;
      }

      &.search-btn {
        margin-bottom: 10px;
      }

      &.job-fld {
        min-width: 200px;
      }
    }
  }
}

.profile-menu {
  font-size: 12px;

  .username {
    padding: 10px 15px 0;
  }

  .ant-dropdown-menu-item {
    padding: 0;

    .ant-btn {
      font-size: 12px;
      font-weight: 700;

      span {
        text-decoration: underline;
      }
    }
  }
}

.drawer-panel {
  .ant-drawer-content-wrapper {
    width: 300px;

    .ant-drawer-body {
      padding: 0;
    }
  }
}

.ant-modal > .ant-modal-content {
  border-radius: 8px;
  width: 320px;

  > .ant-modal-close {
    .ant-modal-close-x {
      width: 40px;
      height: 32px;
      line-height: 32px;
      color: $blue-02;
      font-weight: 800;
      font-size: 14px;
    }
  }

  > .ant-modal-header {
    padding: 4px 20px 2px;

    background: #70c6ea4d;
    border-bottom: 2px solid #70c6ea4d;

    .ant-modal-title {
      font-size: 12px;
      font-weight: 800;
      color: $blue-01;
    }
  }

  > .ant-modal-body {
    padding: 5px 20px 20px 20px;
  }
}

.tag-container > .ant-tag {
  @include tag-container;
}

.stuff-item-container {
  width: 100%;

  .stuff-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &:hover {
      background-color: $highlight-light;
    }

    .unassign-action {
      height: 24px;
      display: flex;
      align-items: center;
      padding: 0;

      &:disabled {
        color: $gray-02;

        img {
          filter: opacity(0.25) grayscale(100%);
        }
      }
    }
  }
}
